// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-slide-deck-personality-not-ready---cover{-webkit-transition:visibility .3s,opacity .3s;transition:visibility .3s,opacity .3s;position:absolute;z-index:10;background-color:#e6e6e6;width:100%;height:100%;text-align:center}.traitify--components-slide-deck-personality-not-ready---cover .traitify--components-slide-deck-personality-not-ready---loadingContainer{position:relative;width:100%;display:inline-block;line-height:500px}.traitify--components-slide-deck-personality-not-ready---cover .traitify--components-slide-deck-personality-not-ready---loadingContainer .traitify--components-slide-deck-personality-not-ready---retry{position:relative;text-align:center;margin-top:1em}.traitify--components-slide-deck-personality-not-ready---cover .traitify--components-slide-deck-personality-not-ready---loadingContainer .traitify--components-slide-deck-personality-not-ready---retry .traitify--components-slide-deck-personality-not-ready---label{margin-top:-1.5em;position:absolute;width:100%;text-align:center}.traitify--components-slide-deck-personality-not-ready---cover .traitify--components-slide-deck-personality-not-ready---loadingContainer .traitify--components-slide-deck-personality-not-ready---retry .traitify--components-slide-deck-personality-not-ready---link{position:relative;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": "traitify--components-slide-deck-personality-not-ready---cover",
	"loadingContainer": "traitify--components-slide-deck-personality-not-ready---loadingContainer",
	"retry": "traitify--components-slide-deck-personality-not-ready---retry",
	"label": "traitify--components-slide-deck-personality-not-ready---label",
	"link": "traitify--components-slide-deck-personality-not-ready---link"
};
export default ___CSS_LOADER_EXPORT___;
