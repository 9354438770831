// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results---center{text-align:center}.traitify--components-results---clear{clear:both}.traitify--components-results---hidden{display:none}.traitify--components-results---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results---center{text-align:center}.traitify--components-results---hide{display:none}.traitify--components-results---heading{font-size:13px;font-size:0.8125rem;color:#767676;font-family:\"Helvetica Neue\",Helvetica,Arial,sans-serif;margin-top:25px;text-transform:uppercase}@media(min-width: 48em){.traitify--components-results---heading{font-size:15px;font-size:0.9375rem}}@media(min-width: 71.25em){.traitify--components-results---heading{font-size:18px;font-size:1.125rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results---center",
	"clear": "traitify--components-results---clear",
	"hidden": "traitify--components-results---hidden",
	"visually-hidden": "traitify--components-results---visually-hidden",
	"hide": "traitify--components-results---hide",
	"heading": "traitify--components-results---heading"
};
export default ___CSS_LOADER_EXPORT___;
