// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-type-based-results-personality-type-bar-chart---center{text-align:center}.traitify--components-results-type-based-results-personality-type-bar-chart---clear{clear:both}.traitify--components-results-type-based-results-personality-type-bar-chart---hidden{display:none}.traitify--components-results-type-based-results-personality-type-bar-chart---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-type-based-results-personality-type-bar-chart---chart{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-type-based-results-personality-type-bar-chart---chart *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-type-based-results-personality-type-bar-chart---chart button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-type-based-results-personality-type-bar-chart---center{text-align:center}.traitify--components-results-type-based-results-personality-type-bar-chart---hide{display:none}.traitify--components-results-type-based-results-personality-type-bar-chart---chart{display:table;table-layout:fixed;height:300px;margin:0 auto 190px}@media(min-width: 48em){.traitify--components-results-type-based-results-personality-type-bar-chart---chart{height:350px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-type-based-results-personality-type-bar-chart---center",
	"clear": "traitify--components-results-type-based-results-personality-type-bar-chart---clear",
	"hidden": "traitify--components-results-type-based-results-personality-type-bar-chart---hidden",
	"visually-hidden": "traitify--components-results-type-based-results-personality-type-bar-chart---visually-hidden",
	"chart": "traitify--components-results-type-based-results-personality-type-bar-chart---chart",
	"hide": "traitify--components-results-type-based-results-personality-type-bar-chart---hide"
};
export default ___CSS_LOADER_EXPORT___;
