// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-type-based-results-personality-badge---center{text-align:center}.traitify--components-results-type-based-results-personality-badge---clear{clear:both}.traitify--components-results-type-based-results-personality-badge---hidden{display:none}.traitify--components-results-type-based-results-personality-badge---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-type-based-results-personality-badge---center{text-align:center}.traitify--components-results-type-based-results-personality-badge---hide{display:none}.traitify--components-results-type-based-results-personality-badge---image{width:140px;height:140px;padding:15px;display:inline-block;border-radius:50%}@media(min-width: 48em){.traitify--components-results-type-based-results-personality-badge---image{width:200px;height:200px;padding:25px}}.traitify--components-results-type-based-results-personality-badge---image:nth-of-type(2){margin-left:-20px}@media(min-width: 48em){.traitify--components-results-type-based-results-personality-badge---image:nth-of-type(2){margin-left:-35px}}.traitify--components-results-type-based-results-personality-badge---image img{width:75%;height:75%;margin-top:10%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-type-based-results-personality-badge---center",
	"clear": "traitify--components-results-type-based-results-personality-badge---clear",
	"hidden": "traitify--components-results-type-based-results-personality-badge---hidden",
	"visually-hidden": "traitify--components-results-type-based-results-personality-badge---visually-hidden",
	"hide": "traitify--components-results-type-based-results-personality-badge---hide",
	"image": "traitify--components-results-type-based-results-personality-badge---image"
};
export default ___CSS_LOADER_EXPORT___;
