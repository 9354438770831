// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-dimension-based-results-radar---center{text-align:center}.traitify--components-results-dimension-based-results-radar---clear{clear:both}.traitify--components-results-dimension-based-results-radar---hidden{display:none}.traitify--components-results-dimension-based-results-radar---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-dimension-based-results-radar---radar{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-dimension-based-results-radar---radar *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-dimension-based-results-radar---radar button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-dimension-based-results-radar---center{text-align:center}.traitify--components-results-dimension-based-results-radar---hide{display:none}.traitify--components-results-dimension-based-results-radar---radar{padding:20px 0px;text-align:center}@media(min-width: 60em){.traitify--components-results-dimension-based-results-radar---radar{float:left;padding:0 20px;width:50%;-webkit-box-sizing:border-box;box-sizing:border-box}}.traitify--components-results-dimension-based-results-radar---radar .traitify--components-results-dimension-based-results-radar---radarContainer{width:100%;height:auto;max-width:500px;margin:0px auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-dimension-based-results-radar---center",
	"clear": "traitify--components-results-dimension-based-results-radar---clear",
	"hidden": "traitify--components-results-dimension-based-results-radar---hidden",
	"visually-hidden": "traitify--components-results-dimension-based-results-radar---visually-hidden",
	"radar": "traitify--components-results-dimension-based-results-radar---radar",
	"hide": "traitify--components-results-dimension-based-results-radar---hide",
	"radarContainer": "traitify--components-results-dimension-based-results-radar---radarContainer"
};
export default ___CSS_LOADER_EXPORT___;
