// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".traitify--components-results-personality-recommendation-chart---center{text-align:center}.traitify--components-results-personality-recommendation-chart---clear{clear:both}.traitify--components-results-personality-recommendation-chart---hidden{display:none}.traitify--components-results-personality-recommendation-chart---visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.traitify--components-results-personality-recommendation-chart---container{-webkit-box-sizing:border-box;box-sizing:border-box;color:#333;font:100%/1.5 \"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif;font-weight:400;margin:0;padding:0;width:100%}.traitify--components-results-personality-recommendation-chart---container *{-webkit-box-sizing:border-box;box-sizing:border-box}.traitify--components-results-personality-recommendation-chart---container button{border:none;border-radius:0;cursor:pointer;font-family:\"Source Sans Pro\",\"Open Sans\",\"Helvetica Neue\",Verdana,Arial,sans-serif}.traitify--components-results-personality-recommendation-chart---center{text-align:center}.traitify--components-results-personality-recommendation-chart---hide{display:none}.traitify--components-results-personality-recommendation-chart---container{padding:20px 0px;text-align:center}.traitify--components-results-personality-recommendation-chart---container .traitify--components-results-personality-recommendation-chart---canvasContainer{height:auto;margin:0px auto;max-width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "traitify--components-results-personality-recommendation-chart---center",
	"clear": "traitify--components-results-personality-recommendation-chart---clear",
	"hidden": "traitify--components-results-personality-recommendation-chart---hidden",
	"visually-hidden": "traitify--components-results-personality-recommendation-chart---visually-hidden",
	"container": "traitify--components-results-personality-recommendation-chart---container",
	"hide": "traitify--components-results-personality-recommendation-chart---hide",
	"canvasContainer": "traitify--components-results-personality-recommendation-chart---canvasContainer"
};
export default ___CSS_LOADER_EXPORT___;
